import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/bulletin",
      component: () => import("./components/Bulletin.vue"),
    },
    {
      path: "/",
      component: () => import("./components/Voting.vue"),
    },
    {
      path: "/bulletin-sent",
      component: () => import("./components/BulletinSent.vue"),
    }
  ],
});
