<template>
  <aside class="aside">
    <div :class="sidebarClass">
      <div class="sidebar__inner">
        <div class="sidebar__logo">
          <a class="sidebar__logo-link" href="#">
            <img
              class="sidebar__logo-img"
              src="../assets/images/svg/logo.svg"
              alt="Logo"
            />
            <img
              class="sidebar__logo-imgmini"
              src="../assets/images/svg/logo-c-color.svg"
              alt="Logo-mini"
            />
          </a>
        </div>
        <nav class="menu">
          <ul class="menu__list">
            <li class="menu__item">
              <a
                class="menu__link menu__link--muted"
                href="#"
                title="Рабочий стол"
              >
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2ZM12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M12 11.4C12.5 11.4 12.9 11.8 12.9 12.3C12.9 12.8 12.5 13.2 12 13.2C11.5 13.2 11.1 12.8 11.1 12.3C11.1 11.8 11.5 11.4 12 11.4ZM12 9.40002C10.4 9.40002 9.09998 10.7 9.09998 12.3C9.09998 13.9 10.4 15.2 12 15.2C13.6 15.2 14.9 13.9 14.9 12.3C14.9 10.7 13.6 9.40002 12 9.40002Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M0.800049 11.3H9.80005V13.3H0.800049V11.3ZM13.8 11.3H23.8V13.3H13.8V11.3ZM11.8 5.30005C11.2 5.30005 10.8 4.90005 10.8 4.30005V1.30005C10.8 0.700049 11.2 0.300049 11.8 0.300049C12.4 0.300049 12.8 0.700049 12.8 1.30005V4.30005C12.8 4.90005 12.4 5.30005 11.8 5.30005ZM5.90005 7.90005C5.60005 7.90005 5.40005 7.80005 5.20005 7.60005L3.10005 5.50005C2.70005 5.10005 2.70005 4.50005 3.10005 4.10005C3.50005 3.70005 4.10005 3.70005 4.50005 4.10005L6.60005 6.20005C7.00005 6.60005 7.00005 7.20005 6.60005 7.60005C6.40005 7.80005 6.10005 7.90005 5.90005 7.90005ZM17.8 7.90005C17.5 7.90005 17.3001 7.80005 17.1 7.60005C16.7001 7.20005 16.7001 6.60005 17.1 6.20005L19.2001 4.10005C19.6 3.70005 20.2001 3.70005 20.6 4.10005C21 4.50005 21 5.10005 20.6 5.50005L18.5 7.60005C18.3 7.80005 18 7.90005 17.8 7.90005ZM12.6 11.5C12.5 11.5 12.3 11.5 12.2 11.4C11.7 11.2 11.5 10.6 11.7 10.1L14.6 4.00005C14.8 3.50005 15.4 3.30005 15.9 3.50005C16.4 3.70005 16.6 4.30005 16.4 4.80005L13.5 10.9C13.4 11.2 13 11.5 12.6 11.5Z"
                    fill="#6F7474"
                  />
                </svg>
                Рабочий стол</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Портфель">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 23H4C1.8 23 0 21.2 0 19V8C0 5.8 1.8 4 4 4H20C22.2 4 24 5.8 24 8V19C24 21.2 22.2 23 20 23ZM4 6C2.9 6 2 6.9 2 8V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V8C22 6.9 21.1 6 20 6H4Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M23 15H1C0.4 15 0 14.6 0 14C0 13.4 0.4 13 1 13H23C23.6 13 24 13.4 24 14C24 14.6 23.6 15 23 15Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M8 18C7.4 18 7 17.6 7 17V11C7 10.4 7.4 10 8 10C8.6 10 9 10.4 9 11V17C9 17.6 8.6 18 8 18ZM16 18C15.4 18 15 17.6 15 17V11C15 10.4 15.4 10 16 10C16.6 10 17 10.4 17 11V17C17 17.6 16.6 18 16 18ZM16 6C15.4 6 15 5.6 15 5V3H9V5C9 5.6 8.6 6 8 6C7.4 6 7 5.6 7 5V2C7 1.4 7.4 1 8 1H16C16.6 1 17 1.4 17 2V5C17 5.6 16.6 6 16 6Z"
                    fill="#6F7474"
                  />
                </svg>
                Портфель</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Документооборот">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 24H8.1C4.7 24 2 21.4 2 18V6.1C2 5.8 2.1 5.6 2.3 5.4L7.4 0.3C7.6 0.1 7.8 0 8.1 0H16C19.3 0 22 2.7 22 6.1V18C22 21.3 19.3 24 16 24ZM4 6.5V18C4 20.3 5.8 22 8.1 22H16C18.3 22 20.1 20.3 20.1 18V6.1C20 3.8 18.2 2 16 2H8.5L4 6.5Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M8 8H4C3.4 8 3 7.6 3 7C3 6.4 3.4 6 4 6H7V2C7 1.4 7.4 1 8 1C8.6 1 9 1.4 9 2V7C9 7.5 8.5 8 8 8ZM17 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H17C17.6 11 18 11.4 18 12C18 12.6 17.5 13 17 13ZM14 18H7C6.4 18 6 17.6 6 17C6 16.4 6.4 16 7 16H14C14.6 16 15 16.4 15 17C15 17.6 14.5 18 14 18Z"
                    fill="#6F7474"
                  />
                </svg>
                Документооборот</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Журнал операций">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 7H6C5.4 7 5 6.6 5 6V1C5 0.4 5.4 0 6 0H18C18.6 0 19 0.4 19 1V6C19 6.6 18.6 7 18 7ZM7 5H17V2H7V5ZM17 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H17C17.6 11 18 11.4 18 12C18 12.6 17.5 13 17 13ZM13 18H7C6.4 18 6 17.6 6 17C6 16.4 6.4 16 7 16H13C13.6 16 14 16.4 14 17C14 17.6 13.5 18 13 18Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M18.6 2.5H18.1C18.1 3.2 17.9 3.9 17.7 4.5H18.6C19.4 4.5 20 5.1 20 5.9V20.6C20 21.4 19.4 22 18.6 22H5.4C4.6 22 4 21.4 4 20.6V5.9C4 5.1 4.6 4.5 5.4 4.5H6.2C6.1 3.9 6 3.2 6 2.5H5.4C3.5 2.5 2 4 2 5.9V20.6C2 22.5 3.5 24 5.4 24H18.6C20.5 24 22 22.5 22 20.6V5.9C22 4 20.5 2.5 18.6 2.5Z"
                    fill="#6F7474"
                  />
                </svg>
                Журнал операций</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--active" href="#" title="Общие собрания акционеров">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 12.569C6 12.9099 5.82631 13.2273 5.53919 13.4112L5.07838 13.7062C3.82419 14.5091 3 15.909 3 17.5C3 19.9853 5.01472 22 7.5 22H17.5C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13H11C10.4477 13 10 12.5523 10 12V8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8V12.569ZM12 11V8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8V12.0218C2.19584 13.1769 1 15.1988 1 17.5C1 21.0899 3.91015 24 7.5 24H17.5C21.0899 24 24 21.0899 24 17.5C24 13.9101 21.0899 11 17.5 11H12Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M13.1973 11C13.7078 10.1175 14 9.09286 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 9.63971 2.65775 11.1258 3.72379 12.2088C3.17834 12.5988 2.69572 13.071 2.29404 13.6073C0.875208 12.1637 0 10.1841 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.0609 15.7935 10.0736 15.4185 11H13.1973Z"
                    fill="#6F7474"
                  />
                </svg>
                Общие собрания акционеров</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Дивиденды">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24C5.4 24 0 18.6 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12C24 18.6 18.6 24 12 24ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M11 20C10.4 20 10 19.6 10 19V5C10 4.4 10.4 4 11 4C11.6 4 12 4.4 12 5V19C12 19.6 11.6 20 11 20Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M14 17H8C7.4 17 7 16.6 7 16C7 15.4 7.4 15 8 15H14C14.6 15 15 15.4 15 16C15 16.6 14.6 17 14 17ZM10.5 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H10.4C10.3 12.3 10.4 11.7 10.5 11Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M13 4H11.1C11.2 4.7 11.3 5.3 11.2 6H13C14.4 6 15.6 7.2 15.6 8.6C15.6 10 14.4 11.2 13 11.2H10.9C10.9 11.5 10.8 11.8 10.8 12.1C10.7 12.5 10.6 12.9 10.3 13.2H13C15.6 13.2 17.6 11.1 17.6 8.6C17.6 6.1 15.5 4 13 4Z"
                    fill="#6F7474"
                  />
                </svg>
                Дивиденды</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Новости и события">
                <svg
                  class="menu__link-icon menu__link-icon--multi"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4C5 2.34315 6.34315 1 8 1H20C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H5V4Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <path
                    d="M1 14C1 12.3431 2.34315 11 4 11H5V23H4C2.34315 23 1 21.6569 1 20V14Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <mask id="path-3-inside-1" fill="white">
                    <rect x="8" y="4" width="12" height="8" rx="1" />
                  </mask>
                  <rect
                    class="stroke"
                    x="8"
                    y="4"
                    width="12"
                    height="8"
                    rx="1"
                    stroke="#6F7474"
                    stroke-width="4"
                    mask="url(#path-3-inside-1)"
                  />
                  <rect
                    class="fill"
                    x="8"
                    y="18"
                    width="9"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                  <rect
                    class="fill"
                    x="8"
                    y="14"
                    width="12"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                </svg>
                Новости и события</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Доска объявлений">
                <svg
                  class="menu__link-icon menu__link-icon--stroke"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 3C2 1.89543 2.89543 1 4 1H8C9.10457 1 10 1.89543 10 3V10C10 11.1046 9.10457 12 8 12H4C2.89543 12 2 11.1046 2 10V3Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <path
                    d="M2 18C2 16.8954 2.89543 16 4 16H8C9.10457 16 10 16.8954 10 18V21C10 22.1046 9.10457 23 8 23H4C2.89543 23 2 22.1046 2 21V18Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <path
                    d="M14 3C14 1.89543 14.8954 1 16 1H20C21.1046 1 22 1.89543 22 3V6C22 7.10457 21.1046 8 20 8H16C14.8954 8 14 7.10457 14 6V3Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <path
                    d="M14 14C14 12.8954 14.8954 12 16 12H20C21.1046 12 22 12.8954 22 14V21C22 22.1046 21.1046 23 20 23H16C14.8954 23 14 22.1046 14 21V14Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                </svg>
                Доска объявлений</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Услуги">
                <svg
                  class="menu__link-icon menu__link-icon--path-circle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0636977 0.648903C0.257618 0.131783 0.83403 -0.130222 1.35115 0.0636977L5.35115 1.5637C5.74145 1.71006 6.00003 2.08318 6.00003 2.50003V17C6.00003 17.5523 6.44774 18 7.00003 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H7.00003C5.34317 20 4.00003 18.6569 4.00003 17V3.19303L0.648903 1.93636C0.131783 1.74244 -0.130222 1.16602 0.0636977 0.648903Z"
                      fill="#6F7474"
                    />
                    <circle
                      cx="9"
                      cy="21"
                      r="2"
                      stroke="#6F7474"
                      stroke-width="2"
                    />
                    <circle
                      cx="20"
                      cy="21"
                      r="2"
                      stroke="#6F7474"
                      stroke-width="2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 4V12C4 14.2091 5.79086 16 8 16H20C22.2091 16 24 14.2091 24 12V7.86119C24 7.36619 23.6379 6.94568 23.1483 6.87225L4 4ZM6 6.32237V12C6 13.1046 6.89543 14 8 14H20C21.1046 14 22 13.1046 22 12V8.72237L6 6.32237Z"
                      fill="#6F7474"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Услуги</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Документы">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 24H8.1C4.7 24 2 21.4 2 18V6.1C2 5.8 2.1 5.6 2.3 5.4L7.4 0.3C7.6 0.1 7.8 0 8.1 0H16C19.3 0 22 2.7 22 6.1V18C22 21.3 19.3 24 16 24ZM4 6.5V18C4 20.3 5.8 22 8.1 22H16C18.3 22 20.1 20.3 20.1 18V6.1C20 3.8 18.2 2 16 2H8.5L4 6.5Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M8 8H4C3.4 8 3 7.6 3 7C3 6.4 3.4 6 4 6H7V2C7 1.4 7.4 1 8 1C8.6 1 9 1.4 9 2V7C9 7.5 8.5 8 8 8ZM17 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H17C17.6 11 18 11.4 18 12C18 12.6 17.5 13 17 13ZM14 18H7C6.4 18 6 17.6 6 17C6 16.4 6.4 16 7 16H14C14.6 16 15 16.4 15 17C15 17.6 14.5 18 14 18Z"
                    fill="#6F7474"
                  />
                </svg>
                Документы</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Обращения">
                <svg
                  class="menu__link-icon menu__link-icon--path-rect"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.24926 16.1303C4.69114 16.2441 5 16.6425 5 17.0988V19.6716L8.08579 16.5858C8.46086 16.2107 8.96957 16 9.5 16H20C21.1046 16 22 15.1046 22 14V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V14C2 14.9292 2.63547 15.715 3.49852 15.9371L4.24926 16.1303ZM0 6C0 3.79086 1.79086 2 4 2H20C22.2091 2 24 3.79086 24 6V14C24 16.2091 22.2091 18 20 18H9.5L4.80953 22.6905C4.14177 23.3582 3 22.8853 3 21.9409V17.874C1.27477 17.4299 0 15.8638 0 14V6Z"
                    fill="#6F7474"
                  />
                  <rect
                    x="5"
                    y="7"
                    width="14"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                  <rect
                    x="5"
                    y="11"
                    width="11"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                </svg>
                Обращения</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Вопросы и ответы">
                <svg
                  class="menu__link-icon menu__link-icon--path"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24C5.4 24 0 18.6 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12C24 18.6 18.6 24 12 24ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M10.6 13.8V11.6C11.2 11.5 11.8 11.2 12.3 10.9C12.8 10.6 13.1 10.2 13.1 9.79998C13.1 8.99998 12.4 8.59998 11.4 8.59998C10.6 8.59998 9.70005 8.89998 8.80005 9.39998V7.49998C9.60005 6.89998 10.6 6.59998 11.8 6.59998C12.8 6.59998 13.6 6.89998 14.3 7.39998C15 7.89998 15.3 8.59998 15.3 9.59998C15.3 10.3 15 10.9 14.5 11.5C14 12 13.3 12.4 12.6 12.6V13.8H10.6ZM10.3 16.1C10.3 15.4 10.9 14.8 11.6 14.8C12.3 14.8 12.9 15.4 12.9 16.1C12.9 16.8 12.3 17.4 11.6 17.4C10.9 17.4 10.3 16.8 10.3 16.1Z"
                    fill="#6F7474"
                  />
                </svg>
                Вопросы и ответы</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Приём акционеров">
                <svg
                  class="menu__link-icon menu__link-icon--stroke-circle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="6.5"
                    r="5.5"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                  <path
                    d="M1 21C1 18.2386 3.23858 16 6 16H18C20.7614 16 23 18.2386 23 21V23H1L1 21Z"
                    stroke="#6F7474"
                    stroke-width="2"
                  />
                </svg>
                Приём акционеров</a
              >
            </li>
            <li class="menu__item">
              <a class="menu__link menu__link--muted" href="#" title="Опросы">
                <svg
                  class="menu__link-icon menu__link-icon--path-rect"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1298 12.8775C20.9697 11.7077 19.082 11.7076 17.9226 12.8767L12.4772 18.3552C12.3616 18.4716 12.2763 18.6149 12.2287 18.7725L11.0428 22.7092C10.9377 23.0582 11.0293 23.4372 11.2819 23.6983C11.4712 23.8938 11.7283 24 11.9919 24C12.0802 24 12.1692 23.9881 12.2567 23.9636L16.26 22.8455C16.4247 22.7995 16.5749 22.7113 16.6959 22.5895L22.1299 17.1206C23.29 15.9508 23.29 14.0474 22.1298 12.8775ZM15.4791 20.9881L13.4651 21.5507L14.0545 19.594L17.7287 15.8974L19.1317 17.312L15.4791 20.9881ZM20.7279 15.7057L20.5357 15.899L19.133 14.4846L19.3246 14.2919C19.7113 13.902 20.3405 13.902 20.7272 14.2919C21.1139 14.6819 21.1139 15.3163 20.7279 15.7057Z"
                    fill="#6F7474"
                  />
                  <path
                    d="M5 2H16C17.1046 2 18 2.89543 18 4V9C18 9.55228 18.4477 10 19 10C19.5523 10 20 9.55228 20 9V4C20 1.79086 18.2091 0 16 0H5C2.79086 0 1 1.79086 1 4V20C1 22.2091 2.79086 24 5 24H8C8.55228 24 9 23.5523 9 23C9 22.4477 8.55228 22 8 22H5C3.89543 22 3 21.1046 3 20V4C3 2.89543 3.89543 2 5 2Z"
                    fill="#6F7474"
                  />
                  <rect
                    x="5"
                    y="5"
                    width="11"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                  <rect
                    x="5"
                    y="9"
                    width="11"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                  <rect
                    x="5"
                    y="13"
                    width="8"
                    height="2"
                    rx="1"
                    fill="#6F7474"
                  />
                </svg>
                Опросы</a
              >
            </li>
          </ul>
        </nav>
        <div class="logout-mobile">
          <a @click.prevent="logOut" class="logout__link btn btn--dark" href="#">Выйти</a>
        </div>
        <div class="sidebar__btn menu-button">
          <div class="sidebar__btn-icon menu-button__icon">
            <svg
              class="sidebar__btn-svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 12H3M3 12L8 7M3 12L8 17"
                stroke="url(#paint0_linear)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="3"
                  y1="7"
                  x2="11.4906"
                  y2="22.283"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#45AE00" />
                  <stop offset="1" stop-color="#08944A" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <a
            @click.prevent="closeSidebar"
            class="sidebar__btn-link menu-button__text"
            href="javascript:void(0);"
            >Свернуть меню</a
          >
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Aside",
  computed: {
    ...mapState(['sidebarOpened']),
    sidebarClass() {
      let classes = ['sidebar'];
      if (this.sidebarOpened) {
        classes.push('sidebar--open');
      }
      return classes;
    }
  },
  methods: {
    ...mapMutations(['logOut', 'closeSidebar']),
  },
}
</script>

<style lang="css">
  .menu__link--muted {
    opacity: .5;
    pointer-events: none;
    -webkit-user-select: none;
  }
</style>