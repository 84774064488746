<template>
  <div class="login">
        <div class="block-left">
            <header class="login-header">
                <button class="menu__toggle">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <a href="/" class="logo">
                    <img src="@/assets/images/logo.svg" alt="Статус онлайн">
                </a>
                
       
            </header>

            <div class="login-access login-main active">

                <div class="issuer">
                    <form @submit.prevent="handleSubmit" action="/" class="form-issuer form novalid">
                        <div class="form-item">
                            <label for="form-issuer__login">Логин или номер телефона</label>
                            <input v-model="login" type="text" name="login" id="form-issuer__login" required>
                        </div>
                        <div class="form-item">
                            <label for="form-issuer__password">Пароль</label>
                            <input v-model="password" :type="showPassword ? 'text' : 'password'" name="password" id="form-issuer__password" required>
                            <a class="password-visibility"  @click="showPassword = !showPassword">
                                <svg><use xlink:href="@/assets/images/svg_sprite.svg#visibility"></use></svg>
                            </a>
                        </div>

                        <div v-if="isError" class="block-error">
                            <span class="block-error__icon">
                                <svg><use xlink:href="@/assets/images/svg_sprite.svg#error"></use></svg>
                            </span>
                            <span class="block-error__text">Неверный логин или пароль</span>
                        </div>

                        <div class="button-wrapper">
                            <button class="button">Войти</button>     
                        </div>
                    </form>
                </div>

                <div class="clientel-phone">
                    <h3 class="header_3 clientel-phone-title">Сервисом пользуется</h3>
                    <div class="clientel-phone-wrap">
                        <ul class="clientel-phone-list">
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone1.png" alt="ПАО Сбербанк">
                                <span class="clientel-phone-text">ПАО Сбербанк</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone2.png" alt="ПАО ФСК ЕЭС">
                                <span class="clientel-phone-text">ПАО “ФСК ЕЭС”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone3.png" alt="ПАО Россети">
                                <span class="clientel-phone-text">ПАО “Россети”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone4.png" alt="ПАО КАМАЗ">
                                <span class="clientel-phone-text">ПАО “КАМАЗ”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone5.png" alt="Другие">
                                <span class="clientel-phone-text">И еще 5150 эмитентов</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="login-noentry login-main">
                <h1 class="header_1">Восстановление пароля</h1>
                <form action="/" class="form-noentry form error">
                    <div class="form-item">
                        <label for="form-noentry__login">Логин</label>
                        <input type="text" name="login" id="form-noentry__login" required>
                    </div>
                    <div class="block-error">
                        <span class="block-error__icon">
                            <svg><use xlink:href="@/assets/images/svg_sprite.svg#error"></use></svg>
                        </span>
                        <span class="block-error__text">Пользователь с таким логином не зарегистрирован</span>
                    </div>
                    <button class="button">Восстановить пароль</button>
                    <a class="back-link">Выполнить вход</a>
                </form>

                <div class="clientel-phone">
                    <h3 class="header_3 clientel-phone-title">Сервисом пользуется</h3>
                    <div class="clientel-phone-wrap">
                        <ul class="clientel-phone-list">
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone1.png" alt="ПАО Сбербанк">
                                <span class="clientel-phone-text">ПАО Сбербанк</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone2.png" alt="ПАО ФСК ЕЭС">
                                <span class="clientel-phone-text">ПАО “ФСК ЕЭС”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone3.png" alt="ПАО Россети">
                                <span class="clientel-phone-text">ПАО “Россети”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone4.png" alt="ПАО КАМАЗ">
                                <span class="clientel-phone-text">ПАО “КАМАЗ”</span>
                            </li>
                            <li class="clientel-phone-item">
                                <img src="@/assets/images/clientel-phone5.png" alt="Другие">
                                <span class="clientel-phone-text">И еще 5150 эмитентов</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="login-about-entrys login-main">
                <div class="close-link">
                    <span class="close-link-icon">
                        <svg><use xlink:href="@/assets/images/svg_sprite.svg#close"></use></svg>
                    </span>
                    <span class="close-link-text">закрыть</span>
                </div>
                <h1 class="header_1">О сервисе кабинета акционера</h1>
                <div class="text">
                    Удобный и безопасный информационный сервис для участия акционера в 
                    корпоративных действиях Эмитентов и взаимодействия акционера с Регистратором.
                </div>
                <h2 class="header_2">Возможности и преимущества*</h2>
                <ul class="list">
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image1.svg" alt="Портфель" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Портфель</div>
                            <div class="item__text text-default">
                                Информация о Ваших ценных бумагах, их 
                                количестве и стоимости, структуре портфеля, анкетные данные.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image2.svg" alt="Общие собрания акционеров" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Общие собрания акционеров</div>
                            <div class="item__text text-default">
                                Принимайте участие в общих собраниях акционеров в режиме 
                                онлайн (дистанционно) — из любой точки мира и когда Вам удобно.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image3.svg" alt="Услуги" class="image">
                        </div>
                        <div class="aitem__wrapper">
                            <div class="item__title">Услуги</div>
                            <div class="item__text text-default">
                                Онлайн операции со счетами и ценными бумагами.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image4.svg" alt="Дивиденды" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Дивиденды</div>
                            <div class="-item__text text-default">
                                Контролируйте сумму начисленных дивидендов.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image5.svg" alt="Журнал операций" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Журнал операций</div>
                            <div class="item__text text-default">
                                Все операции с ценными бумагами под оперативным контролем.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image6.svg" alt="Обращения" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Обращения</div>
                            <div class="item__text text-default">
                                Требуется онлайн-консультация — обратитесь к Регистратору или Эмитенту.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image7.svg" alt="Новости и события" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Новости и события</div>
                            <div class="item__text text-default">
                                Будьте в курсе корпоративных событий Эмитентов.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image8.svg" alt="Документы" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Документы</div>
                            <div class="item__text text-default">
                                Полная библиотека документов для взаимодействия с Регистратором.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image9.svg" alt="Вопросы и ответы" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Вопросы и ответы</div>
                            <div class="item__text text-default">
                                Детальная информация по вопросам взаимодействия с Регистратором и Эмитентом.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-entrys-image10.svg" alt="Информирование о событиях" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Информирование о событиях</div>
                            <div class="item__text text-default">
                                О всех важных событиях в сервисе Вы будете получать веб и e-mail уведомления.
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="notes text-default">
                    * - полный перечень возможностей и функций Сервиса доступен 
                    для акционеров, зарегистрированных в реестре владельцев ценных бумаг.
                </div>
                <h2 class="header_2">Сервис доступен на любом устройстве*</h2>
                <div class="item adaptive">
                    <div class="image-wrapper">
                        <img src="@/assets/images/adaptive.svg" alt="Информирование о событиях" class="image">
                    </div>
                    <div class="item__wrapper">
                        <div class="item__text text-default">
                            * - для использования Сервиса мы рекомендуем удобные и безопасные 
                            браузеры — <a href="#">Яндекс Браузер</a>, <a href="#">Google Chrome</a> или <a href="#">Apple Safari</a>.
                        </div>
                    </div>
                </div>
                <h2 class="header_2">О сервисе кабинета акционера*</h2>
                <ol class="list-counter">
                    <li>С помощью логина и пароля СТАТУС Онлайн - обратитесь в <a href="#">офисы обслуживания.</a></li>
                    <li>Используя подтвержденную учетную запись портала Госуслуг. <a class="gosuslugi-more">Подробнее</a></li>
                    <li>Для акционеров доступен вход через "Сбербанк ID" (Сбербанк Онлайн). <a href="#">Подробнее</a></li>
                </ol>
                
                <div class="notes text-default">
                    * - с акционеров не взимается оплата за предоставление доступа к Сервису по лицевым счетам в реестрах эмитентов, 
                    заключивших с АО «СТАТУС» Договор (соглашение) о предоставлении доступа к Сервису своим акционерам.
                </div>
        
                <h2 class="header_2">У Вас есть вопросы - свяжитесь с нами</h2>
                <div class="blocks-wrap">
                    <a href="mailto:office@rostatus.ru" class="block-email">
                        <span class="block-email__icon">
                            <svg><use xlink:href="@/assets/images/svg_sprite.svg#email"></use></svg>
                        </span>
                        <span class="block-email__text">office@rostatus.ru</span>
                    </a>
                    <a href="tel:+74952800487" class="block-phone">
                        <span class="block-phone__icon">
                            <svg><use xlink:href="@/assets/images/svg_sprite.svg#phone"></use></svg>
                        </span>
                        <span class="block-phone__text">+7 (495) 280-04-87</span>
                    </a>
                </div>
                <div class="copyright">
                    АО «СТАТУС» является профессиональным участником рынка ценных бумаг и с 1997 года 
                    оказывает услуги по ведению реестра владельцев ценных бумаг на основании лицензии, 
                    выданной ФСФР России 12.03.2004 № 10-000-1-00304 без ограничения срока действия. 
                    АО «СТАТУС» является членом СРО НФА и ассоциированным членом ПАРТАД. В настоящее 
                    время АО «СТАТУС» обеспечивает ведение реестров и предлагает полный спектр услуг 
                    более 5600 акционерным обществам, расположенным в различных регионах России. АО «СТАТУС» 
                    имеет самую обширную трансфер-агентскую сеть — обслуживание эмитентов и акционеров организовано 
                    более чем в 140 населенных пунктах России. АО «СТАТУС» имеет индивидуальный рейтинг надежности 
                    на уровне «ААА» (максимальная надежность), присвоенный Национальным Рейтинговым Агентством.
                </div>
            </div>

            <div class="login-about-issuer login-main">
                <div class="close-link">
                    <span class="close-link-icon">
                        <svg><use xlink:href="@/assets/images/svg_sprite.svg#close"></use></svg>
                    </span>
                    <span class="close-link-text">закрыть</span>
                </div>
                <h1 class="header_1">Кабинет эмитента</h1>
                <div class="text">
                    Удобный и безопасный информационный сервис для представителей Эмитента для 
                    обмена информацией с регистратором и своими акционерами.
                </div>
                <h2 class="header_2">Возможности и преимущества*</h2>
                <ul class="list">
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-issuer-image1.svg" alt="Юридически значимый документооборот" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Юридически значимый документооборот</div>
                            <div class="item__text text-default">
                                Оперативно осуществляйте юридически значимый документооборот с регистратором.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-issuer-image2.svg" alt="Запрос информации" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Запрос информации</div>
                            <div class="item__text text-default">
                                Оперативно получайте полную информацию о структуре акционерного капитала 
                                эмитента и открытых ЛС. Контролируйте сумму начисленных дивидендов.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-issuer-image3.svg" alt="Новости для Ваших акционеров" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Новости для Ваших акционеров</div>
                            <div class="item__text text-default">
                                Публикуйте новости и опросы мнений для своих акционеров.
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-issuer-image4.svg" alt="Организация собраний" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Организация собраний</div>
                            <div class="-item__text text-default">
                                Размещайте онлайн заявки на проведение собраний удобным выбором даты и времени.*
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="image-wrapper">
                            <img src="@/assets/images/about-issuer-image5.svg" alt="Документы" class="image">
                        </div>
                        <div class="item__wrapper">
                            <div class="item__title">Документы</div>
                            <div class="item__text text-default">
                                Полная библиотека документов для взаимодействия с Регистратором.
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="notes text-default">
                    * - сервис находится в разработке.
                </div>
                <h2 class="header_2">Как подключиться?</h2>
                <ol class="list-counter">
                    <li>Направьте скан копию заполненной и подписанной <a href="#">анкеты</a>, а также скан доверенности с печатью организации на адрес <a href="mailto:emitent@rostatus.ru">emitent@rostatus.ru</a>.</li>
                    <li>Получите по указанному в анкете e-mail письмо с регистраций и Вашим логином.</li>
                    <li>Получите свой пароль, воспользовавшись <a href="#">сервисом восстановления пароля.*</a></li>
                </ol>

                <div class="notes text-default">
                    * - для получения пароля потребуется ввести код из СМС, который мы направим на 
                    указанный в анкете номер мобильного телефона доверенного лица.
                </div>

                <div class="item adaptive">
                    <div class="image-wrapper">
                        <img src="@/assets/images/adaptive.svg" alt="Информирование о событиях" class="image">
                    </div>
                    <div class="item__wrapper">
                        <div class="item__text text-default">
                            * - для использования Сервиса мы рекомендуем удобные и безопасные 
                            браузеры — <a href="#">Яндекс Браузер</a>, <a href="#">Google Chrome</a> или <a href="#">Apple Safari</a>.
                        </div>
                    </div>
                </div>

                <h2 class="header_2">У Вас есть вопросы - свяжитесь с нами</h2>
                <div class="blocks-wrap">
                    <a href="mailto:office@rostatus.ru" class="block-email">
                        <span class="block-email__icon">
                            <svg><use xlink:href="@/assets/images/svg_sprite.svg#email"></use></svg>
                        </span>
                        <span class="block-email__text">office@rostatus.ru</span>
                    </a>
                    <a href="tel:+74952800487" class="block-phone">
                        <span class="block-phone__icon">
                            <svg><use xlink:href="@/assets/images/svg_sprite.svg#phone"></use></svg>
                        </span>
                        <span class="block-phone__text">+7 (495) 280-04-87</span>
                    </a>
                </div>
                <div class="copyright">
                    АО «СТАТУС» является профессиональным участником рынка ценных бумаг и с 1997 года 
                    оказывает услуги по ведению реестра владельцев ценных бумаг на основании лицензии, 
                    выданной ФСФР России 12.03.2004 № 10-000-1-00304 без ограничения срока действия. 
                    АО «СТАТУС» является членом СРО НФА и ассоциированным членом ПАРТАД. В настоящее 
                    время АО «СТАТУС» обеспечивает ведение реестров и предлагает полный спектр услуг 
                    более 5600 акционерным обществам, расположенным в различных регионах России. АО «СТАТУС» 
                    имеет самую обширную трансфер-агентскую сеть — обслуживание эмитентов и акционеров организовано 
                    более чем в 140 населенных пунктах России. АО «СТАТУС» имеет индивидуальный рейтинг надежности 
                    на уровне «ААА» (максимальная надежность), присвоенный Национальным Рейтинговым Агентством.
                </div>
            </div>

            <div class="login-gosuslugi-entry login-main">
                <div class="close-link">
                    <span class="close-link-icon">
                        <svg><use xlink:href="@/assets/images/svg_sprite.svg#close"></use></svg>
                    </span>
                    <span class="close-link-text">закрыть</span>
                </div>
                <h1 class="header_1">Вход в кабинет акционера через портал Госуслуг</h1>
                <div class="gosuslugi-entry-text">
                    Обращаем внимание, что для получения доступа к Сервису через портал 
                    Госуслуги при успешной идентификации, не требуется личного обращения 
                    в офисы Регистратора (АО "СТАТУС") – вся процедура осуществляется 
                    дистанционно и занимает несколько минут.
                </div>
                <h2 class="header_2">Для входа в Сервис через портал Госуслуг необходимо</h2>
                <ul class="list-disc">
                    <li>Использовать подтвержденную учетную запись Госуслуги. <a href="#">Подробнее</a></li>
                    <li>
                        Пройти автоматическую процедуру идентификации - сверки вашего ФИО и паспорта, 
                        указанных в учетной записи на портале Госуслуги с анкетными данными хранящимся 
                        в реестре акционеров соответствующих Эмитентов. В случае отрицательных 
                        результатов идентификации Сервис отобразит сообщение с дальнейшими шагами.
                    </li>
                </ul>
                <p></p>
                <h2 class="header_2">Вход в Сервис через портал Госуслуг доступен</h2>
                <ul class="list-disc">
                    <li>Действующим Пользователям Сервиса (получившим логин и пароль СТАТУС Онлайн).</li>
                    <li>
                        Держателям ценных бумаг (акционерам) Эмитентов заключивших с Регистратором (АО "СТАТУС") 
                        договор о предоставлении доступа к Сервису своим держателям ценных бумаг 
                        (полнофункциональная версия или функция электронного голосования на общих собраниях акционеров). 
                        Актуальный перечень указанных Эмитентов публикуется на странице входа в Сервис и в разделе События.
                    </li>
                </ul>
                <p></p>
                <h2 class="header_2">Порядок входа в Сервис через портал Госуслуг</h2>
                <h3 class="header_3">Действующий Пользователь Сервиса</h3>
                <ol class="list-counter">
                    <li>Нажмите на кнопку Госуслуги на странице входа Сервиса - вы будете автоматически перенаправлены на страницу входа портала Госуслуги.</li>
                    <li>Войдите на портал Госуслуги с помощью вашей учетной записи.</li>
                    <li>Согласитесь с передачей в Сервис идентификационных данных (ФИО и номера, серии паспорта).</li>
                    <li>Дождитесь окончания процедуры идентификации и входа в Сервис.</li>
                    <li>Пользуйтесь Сервисом!</li>
                </ol>
                <p></p>
                <h3 class="header_3">Новый Пользователь Сервиса</h3>
                <ol class="list-counter">
                    <li>Нажмите на кнопку Госуслуги на странице входа Сервиса - вы будете автоматически перенаправлены на страницу входа портала Госуслуги.</li>
                    <li>Войдите на портал Госуслуги с помощью вашей учетной записи.</li>
                    <li>Согласитесь с передачей в Сервис идентификационных данных (ФИО и номера, серии паспорта).</li>
                    <li>Дождитесь окончания процедуры идентификации и входа в Сервис.</li>
                    <li>Для использования Сервис укажите ваш адрес электронной почты, номер мобильного телефона, кодовое слово.</li>
                    <li>Установите пароль для персональной учетной записи СТАТУС Онлайн.</li>
                    <li>По окончанию процедуры вы получите информационное SMS сообщение об активации учетной записи СТАТУС Онлайн.</li>
                    <li>Пользуйтесь Сервисом!</li>
                </ol>
                <p></p>
                <h3 class="header_3">Дополнительно</h3>
                <a href="#" class="link-more">Частые вопросы на портале Госуслуги</a>
                <a href="#" class="link-more">Регламент доступа и использования Сервиса</a>
                <div class="copyright">
                    АО «СТАТУС» является профессиональным участником рынка ценных бумаг и с 1997 года 
                    оказывает услуги по ведению реестра владельцев ценных бумаг на основании лицензии, 
                    выданной ФСФР России 12.03.2004 № 10-000-1-00304 без ограничения срока действия. 
                    АО «СТАТУС» является членом СРО НФА и ассоциированным членом ПАРТАД. В настоящее 
                    время АО «СТАТУС» обеспечивает ведение реестров и предлагает полный спектр услуг 
                    более 5600 акционерным обществам, расположенным в различных регионах России. АО «СТАТУС» 
                    имеет самую обширную трансфер-агентскую сеть — обслуживание эмитентов и акционеров организовано 
                    более чем в 140 населенных пунктах России. АО «СТАТУС» имеет индивидуальный рейтинг надежности 
                    на уровне «ААА» (максимальная надежность), присвоенный Национальным Рейтинговым Агентством.
                </div>
            </div>

            <footer class="login-footer">
                <div class="footer-text">
                    © АО «СТАТУС», 2020. Все права защищены. <br />
                    Сервис предоставлен компанией АО «СТАТУС». <br />
                    Техническая поддержка: <a href="mailto:support@rostatus.ru">support@rostatus.ru</a> <br />
                    ИНН 7707179242, ОГРН 1027700003924 109052, <br />
                    Москва, Новохохловская улица, 23 стр. 1, помещение 1
                </div>
                <div class="footer-shops">
                    <a target="_blank" href="https://apps.apple.com/ru/app/%D1%81%D1%82%D0%B0%D1%82%D1%83%D1%81-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1513121933" class="footer-shop image-wrapper">
                        <img src="@/assets/images/appstore.png" alt="App Store">
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.status.statusmobile&hl=ru" class="footer-shop image-wrapper">
                        <img src="@/assets/images/playmarket.png" alt="Google Play">
                    </a>
                </div>
            </footer>
        </div>
        <div class="block-right">
            <div class="image-content image-wrapper">
                <img src="@/assets/images/man-background.jpg" alt="Статус онлайн" class="man-image">
                <img src="@/assets/images/girl-background.jpg" alt="Статус онлайн" class="girl-image">
            </div>
            <div class="clientel">
                <div class="clientel-title">Сервисом пользуется</div>
                <ul class="clientel-list">
                    <li class="clientel-item">
                        <div class="image-wrapper clientel-image-wrapper">
                            <img src="@/assets/images/clientel1.png" alt="ПАО Сбербанк" style="width: 3.9vw;">
                        </div>
                        <span class="clientel-text">ПАО Сбербанк</span>
                    </li>
                    <li class="clientel-item">
                        <div class="image-wrapper clientel-image-wrapper">
                            <img src="@/assets/images/clientel2.png" alt="ПАО ФСК ЕЭС" style="width: 3.5vw;">
                        </div>
                        <span class="clientel-text">ПАО “ФСК ЕЭС”</span>
                    </li>
                    <li class="clientel-item">
                        <div class="image-wrapper clientel-image-wrapper">
                            <img src="@/assets/images/clientel3.png" alt="ПАО Россети" style="width: 2.8vw;">
                        </div>
                        <span class="clientel-text">ПАО “Россети”</span>
                    </li>
                    <li class="clientel-item">
                        <div class="image-wrapper clientel-image-wrapper">
                            <img src="@/assets/images/clientel4.png" alt="ПАО КАМАЗ" style="width: 2.7vw;">
                        </div>
                        <span class="clientel-text">ПАО “КАМАЗ”</span>
                    </li>
                    <li class="clientel-item">
                        <div class="image-wrapper clientel-image-wrapper">
                            <img src="@/assets/images/clientel5.svg" alt="Другие" style="width: 5.2vw;">
                        </div>
                        <span class="clientel-text">И еще 5 150 <br>эмитентов</span>
                    </li>
                </ul>
            </div>
        </div>
        <link href="/main.css" rel="stylesheet" />
    </div>
    
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      login: "",
      password: "",
      showPassword: false,
    }
  },
  methods: {
    ...mapMutations([
      'logIn',
      'setLogin',
      'setPassword',
    ]),
    ...mapActions([
      'tryLogin'
    ]),
    handleSubmit() {
      let user = {
        login: this.login,
        password: this.password,
      };

      this.tryLogin(user);
    }
  },
  computed: {
    ...mapState(['isError']),
  }
}
</script>