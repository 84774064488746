<template>
  <div id="app">
    <div v-if="isLogged">
      <Aside />
      <main class="main">
        <div class="main__content">
          <Header />
          <router-view />
          <Footer />
        </div>
      </main>
    </div>
    <div v-else>
      <Login />
    </div>
    
  </div>
</template>

<script>
import Login from "@/components/Login";
import Aside from "@/components/Aside";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState(['isLogged']),
  },
  created() {
    this.tryToLoginFromSessionStorage();
  },
  methods: {
    ...mapActions(['tryToLoginFromSessionStorage']),
  },
  components: {
    Login,
    Aside,
    Header,
    Footer,
  },
};
</script>
