import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    isError: false,
    login: 'test',
    password: '123',
    sidebarOpened: false,
  },
  mutations: {
    logIn(state) {
      state.isLogged = true;
    },
    logOut(state) {
      state.isLogged = false;
      sessionStorage.removeItem('status_user');
    },
    setError(state) {
      state.isError = true;
    },
    unsetError(state) {
      state.isError = false;
    },
    openSidebar(state) {
      state.sidebarOpened = true;
    },
    closeSidebar(state) {
      state.sidebarOpened = false;
    },
  },
  actions: {
    tryLogin({ commit, state }, user) {
      //If credentials are correct
      if (state.login == user.login && state.password == user.password) {
        commit('unsetError');
        commit('logIn');
        sessionStorage.setItem('status_user', JSON.stringify(user));
      } else {
        commit('setError');
      }
    },
    tryToLoginFromSessionStorage({ commit, state }) {
      console.log('logging from session...');
      try {
        let user = JSON.parse(sessionStorage.getItem('status_user'));
        if (user !== null && state.login == user.login && state.password == user.password) {
          commit('logIn');
        }
      } catch(e) {
        console.error(e);
        sessionStorage.removeItem('status_user');
      }
    },
  },
});